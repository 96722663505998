<template>
  <app-list-view
    server-side
    app="questionnaire"
    model="questionnaireheader"
    api-url="questionnaire/questionnaire-header/"
    :title="$t('menu.questionnaire')"
    :createTo="{ name: 'questionnaireCreate' }"
    :editTo="{ name: 'questionnaireEdit' }"
    :headers="headers"
  >
  </app-list-view>
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'questionnaireList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.name'), value: 'name' },
        {
          text: this.$t('fields.questionnaireType'),
          value: 'questionnaire_type_id.name'
        },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'questionnaire'
    })
  }
}
</script>
